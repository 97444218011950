import numeral from "numeral";
import { format, parseISO } from "date-fns";

export function roundNumber(value: number | string): string {
  const number = +value;
  if (Number.isInteger(number)) return number.toString();
  return number.toFixed(2);
}

export const addFormatting = (number: number | string): string => {
  if (Number.isInteger(+number)) {
    return numeral(+number).format("0,0");
  }

  return numeral(roundNumber(number)).format("0,0.00");
};

export const addFormattingUptoTwoDecimals = (number: number | string): string => {
  if (Number.isInteger(+number)) {
    return numeral(+number).format("0,0.00");
  }

  return numeral(roundNumber(number)).format("0,0.00");
};

export const removeFormatting = (rawNumber: string): number =>
  +rawNumber.toString().replace(/[^-0-9.]/g, "");

export const formatDDMMYYYY = (date: string): string => {
  const initialDate = parseISO(date);
  return format(initialDate, "dd-MM-yyyy");
};

export const formatNumberWithShortening = (number: number | string): string => {
  const numericValue = +number;

  if (numericValue >= 1e9) {
    return `${numeral(numericValue / 1e9).format("0,0.0")}B`;
  }
  if (numericValue >= 1e6) {
    return `${numeral(numericValue / 1e6).format("0,0.0")}M`;
  }
  return addFormatting(number);
};

export const calculatePercentages = (numbers: number[]) => {
  const total = numbers?.reduce((sum, num) => sum + num, 0);
  if (total === 0) {
    return numbers.map(() => "0.00");
  }
  return numbers?.map((num) => ((num / total) * 100).toFixed(2));
};

export function calculateRemainingAmount(
  baseNumberStr: string,
  percentValue1: string,
  percentValue2: string,
  percentValue3: string,
) {
  const baseNumber = parseFloat(baseNumberStr);
  const totalPercentage =
    parseFloat(percentValue1) + parseFloat(percentValue2) + parseFloat(percentValue3);
  const result = baseNumber * (1 - totalPercentage / 100);
  return Number.isNaN(result) ? "" : result.toFixed(2).toString();
}
