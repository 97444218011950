import { InsightsDataProps } from "../../lib/dashboard/insights/types";
import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";

const customerProfitabilityEndpoint = "/api/dashboard/insights/repeat-customer-profitability";

const ENDPOINTS = {
  fetchRepeatCustomerProfitability: (params: InsightsDataProps) =>
    `${customerProfitabilityEndpoint}?${createFilteredUrlParams<InsightsDataProps>(params)}`,
};

export { ENDPOINTS };
