import React from "react";

export type TShowGroupByDropdown = {
  showGroupByDropdown: boolean;
  setShowGroupByDropdown: (b: boolean) => void;
};

export function useShowGroupByDropdown(): TShowGroupByDropdown {
  const [showGroupByDropdown, setShowGroupByDropdown] = React.useState(false);

  return <TShowGroupByDropdown>{
    showGroupByDropdown,
    setShowGroupByDropdown,
  };
}
