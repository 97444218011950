import { ApexOptions } from "apexcharts";
import format from "date-fns/format";
import { isValid, parseISO } from "date-fns";
import { addFormatting } from "./format";
import { CustomError } from "../../lib/errorTypes";

const openWindow = (url: string, onSuccess: () => void) => {
  const authWindow = window.open(
    url,
    "Auth",
    "width=1000,height=700,left=500,top=100,status=yes,toolbar=no,menubar=no,location=no",
  );

  const timer = setInterval(() => {
    if (authWindow?.closed) {
      clearInterval(timer);
      onSuccess();
    }
  }, 1000);
};

function getErrorMessage(error: any) {
  let message = "";

  if (!error) {
    return message;
  }

  if (typeof error === "string") {
    return error;
  }

  if ("customError" in error) {
    return error.customError as CustomError;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (error?.details) {
    return error?.details;
  }
  if (error?.errors?.length) {
    Object.values(error.errors).forEach((err: any) => {
      message += ` ${err.message}\n`;
    });
  } else if (error?.message) {
    message = error.message;
  } else if (error?.errorMessage) {
    message = error.errorMessage;
  } else if (typeof error === "object") {
    Object.values(error).forEach((err) => {
      message += ` ${err}\n`;
    });
  }

  if (message?.startsWith("Unexpected JSON")) {
    return "Server error.";
  }
  return message;
}

const getRandomNumbersArray = (length: number = 15) =>
  Array.from({ length }, () => Math.floor(Math.random() * 15));

const cardChartOptions: ApexOptions = {
  chart: {
    background: "transparent",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    padding: {
      left: 0,
      right: 0,
    },
    show: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  tooltip: {
    enabled: false,
  },
};

interface IChartOptionsProps extends Omit<ApexOptions, "title"> {
  title?: string;
  gridLines?: boolean;
  isRotate?: boolean;
  fontSize?: string;
  categories?: string[];
  isLoading?: boolean;
  xaxisColors?: string | string[];
  customOptions?: ApexOptions;
  customChartOptions?: ApexChart;
  customGridOptions?: ApexGrid;
  customPlotOptions?: ApexPlotOptions;
  dateRange?: boolean;
  legendOptions?: any;
  customXaxisFormatter?: any;
  customYaxisFormatter?: any;
  overrideColors?: string[];
  stacked?: boolean;
  tickAmount?: number;
}

export const CHART_COLORS = [
  "var(--primary)",
  "#14B8A6",
  "#FFB547",
  "#F44336",
  "#EF5DA8",
  "#EB8130",
  "#2196F3",
];

export const getChartColors = (isLoading: boolean, overrideColors?: string[]) => {
  if (isLoading) {
    return ["#e9e9e9"];
  }
  return overrideColors || CHART_COLORS;
};

type GetLineChartOptions = (props: IChartOptionsProps) => ApexOptions;

const getLineChartOptions: GetLineChartOptions = ({
  title,
  gridLines = true,
  isRotate = false,
  xaxisColors,
  isLoading,
  categories = [],
  dateRange,
  customOptions,
  customChartOptions,
  fontSize = "14px",
  xaxis,
  stroke,
  customGridOptions,
  customPlotOptions,
  legendOptions,
  customXaxisFormatter,
  customYaxisFormatter,
  overrideColors,
  stacked,
  tickAmount,
  ...rest
}) => ({
  title: {
    text: title,
    offsetX: 8,
    offsetY: 2,
    margin: 40,
    style: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 400,
      fontSize,
      color: "#253858",
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    stacked: stacked === true,
    zoom: { enabled: false },
    ...customChartOptions,
  },
  legend: {
    horizontalAlign: "left",
    markers: {
      // size: 8,
      width: 8,
      height: 8,
    },

    ...legendOptions,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    ...stroke,
  },
  grid: {
    borderColor: "#F0F0F0",
    xaxis: {
      lines: { show: gridLines },
    },
    ...customGridOptions,
  },
  colors: getChartColors(!!isLoading, overrideColors),
  xaxis: {
    ...xaxis,
    axisTicks: { show: false },
    tickAmount: tickAmount || 15,
    labels: {
      offsetX: isRotate ? 10 : 0,
      rotate: isRotate ? -35 : 0,
      rotateAlways: isRotate,
      style: {
        colors: xaxisColors,
        fontFamily: "sofia-pro, sans-serif",
      },
      show: !isLoading,
      ...customXaxisFormatter,
    },
    categories: dateRange
      ? categories.map((category) =>
          category.includes(" - ")
            ? `${format(parseISO(category?.split(" - ")[0]), "d MMM")} - ${format(
                parseISO(category?.split(" - ")[1]),
                "d MMM",
              )}`
            : format(parseISO(category), "d MMM"),
        )
      : (function () {
          return categories.map((category) => {
            let formattedDate = category;
            if (isValid(parseISO(category))) {
              formattedDate = format(parseISO(category), "d MMM");
            }
            return formattedDate;
          });
        })(),
  },
  yaxis: {
    labels: {
      style: {
        colors: "#A3A3A3",
      },
      formatter(val: number) {
        return addFormatting(val);
      },
      ...customYaxisFormatter,
    },
    decimalsInFloat: 2,
  },
  plotOptions: {
    ...customPlotOptions,
  },
  ...customOptions,
  ...rest,
});

export {
  getErrorMessage,
  openWindow,
  cardChartOptions,
  getLineChartOptions,
  getRandomNumbersArray,
};
