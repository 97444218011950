import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

type AppSettingsType = {
  showProfit: boolean;
  setShowProfit: (showProfit: boolean) => void;
};
interface IAppSettingsProvider {
  children: ReactNode;
}

const AppSettingsContext = createContext<AppSettingsType>({
  showProfit: false,
  setShowProfit: () => {},
});

function AppSettingsProvider(props: IAppSettingsProvider) {
  const [showProfit, setShowProfit] = useState(false);
  const value = useMemo(
    () => ({
      showProfit,
      setShowProfit,
    }),
    [showProfit],
  );
  return <AppSettingsContext.Provider value={value} {...props} />;
}

function useAppSettingsContext() {
  const context = useContext(AppSettingsContext);
  if (context === undefined) {
    throw new Error("useAppSettingsContext must be used within an AppSettingsProvider");
  }
  return context;
}

export { AppSettingsProvider, useAppSettingsContext, AppSettingsContext };
