import { useQuery } from "react-query";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { WhitelabelItem } from "../../lib/store/settings/whitelabel/db";
import { useStoresContext } from "../../context/StoresContext";
import api, { queryFetcher } from "../APIResolvers";
import { useWhitelabeling } from "./useWhitelabeling";
import { partnerDomainAgencyMap } from "../../lib/utils/agency";

export const LOCAL_STORAGE_PARTNER_ID_KEY = "signup-partner-id";

const useFetchWhitelabeling = () => {
  const { data, setData } = useWhitelabeling();
  const { isStoresLoading, selectedStore } = useStoresContext();

  const searchParams = useSearchParams();
  const [partnerId, setPartnerId] = useState<string | null>(null);

  const storeId = selectedStore?.id;

  useEffect(() => {
    const partnerIdQuery =
      searchParams?.get("partner") || localStorage.getItem(LOCAL_STORAGE_PARTNER_ID_KEY) || "";
    if (partnerIdQuery) {
      setPartnerId(partnerIdQuery);
    }
  }, [isStoresLoading, searchParams]);

  useEffect(() => {
    if (partnerId) {
      localStorage.setItem(LOCAL_STORAGE_PARTNER_ID_KEY, partnerId);
    }
  }, [partnerId, isStoresLoading]);

  useEffect(() => {
    const url = window.location.hostname;
    const partnerDomainId = partnerDomainAgencyMap[url]?.partnerId;
    if (partnerDomainId) {
      setPartnerId(partnerDomainId);
      setData((prev) => ({
        ...prev,
        partnerId: partnerDomainId,
        isLoadingPartnerId: true,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        partnerId,
        isLoadingPartnerId: false,
      }));
    }
  }, [partnerId, setData]);

  const {
    data: whiteLabelResponse,
    isLoading,
    isSuccess,
  } = useQuery<WhitelabelItem, string>(
    ["whitelabelItem", storeId, partnerId],
    () => queryFetcher(api.whiteLabel.ENDPOINTS.getWhiteLabelItem({ storeId, partnerId })),
    { enabled: !!storeId || !!partnerId },
  );

  useEffect(() => {
    const url = window.location.hostname;
    const partnerDomainId = partnerDomainAgencyMap[url]?.partnerId;

    if (!partnerDomainId) {
      setData((prevData) => ({
        ...prevData,
        response: whiteLabelResponse || data.response,
        isLoading,
        partnerId,
        isSuccess,
        isLoadingPartnerId: false,
      }));

      return;
    }
    setData((prevData) => ({
      ...prevData,
      response: whiteLabelResponse || data.response,
      isLoading,
      partnerId,
      isSuccess,
      isLoadingPartnerId: !isSuccess,
    }));
  }, [data.response, isLoading, isSuccess, partnerId, setData, whiteLabelResponse]);
};

export default useFetchWhitelabeling;
