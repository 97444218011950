import React from "react";
import {
  differenceInDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { useRouter } from "next/router";
import { DATE_OPTIONS, DateOptions } from "../../src/utils/constants";
import { isFullMonth, isFullYear, isMonthToDate, isYearToDate } from "../../src/utils/dates";
import { UseGroupBy, groupByOptions, useGroupBy } from "./groupBy/useGroupBy";
import {
  TShowGroupByDropdown,
  useShowGroupByDropdown,
} from "./showGroupByDropdown/showGroupByDropdown";

export type RangeType = { from: string; to: string };
export type CompareRangeType = { prevStartDate: string; prevEndDate: string };

type DateDropdownContextProps = {
  dateRange: RangeType;
  setSelectedDateIndex: React.Dispatch<React.SetStateAction<DateOptions>>;
  setDateRange: React.Dispatch<React.SetStateAction<any>>;
  selectedDateIndex: number;
  selectedDate: (typeof DATE_OPTIONS)[number];
  beforeNow: number;
  compareRange: CompareRangeType;
  compareSelect: CompareDropdownType;
  changeCompareSelect: React.Dispatch<React.SetStateAction<CompareDropdownType>>;
} & UseGroupBy &
  TShowGroupByDropdown;

export type CompareDropdownType = "previousYear" | "previousPeriod";

const DateDropdownContext = React.createContext<DateDropdownContextProps>({
  dateRange: {
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  setSelectedDateIndex: () => {},
  setDateRange: () => {},
  selectedDateIndex: DateOptions.MTD,
  selectedDate: DATE_OPTIONS[DateOptions.MTD],
  beforeNow: differenceInDays(new Date(), parseISO(format(startOfMonth(new Date()), "yyyy-MM-dd"))),
  compareRange: {
    prevStartDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    prevEndDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
  },
  compareSelect: "previousPeriod",
  changeCompareSelect: () => {},
  groupByValue: groupByOptions[0].id,
  onGroupByChange: (e: any) => {},
  setGroupByValue: () => {},
  showGroupByDropdown: false,
  setShowGroupByDropdown: () => {},
});

export const getPreviousYearDates = (parsedDateFrom: Date, parsedDateTo: Date) => {
  const prevFrom = subYears(parsedDateFrom, 1);
  const prevTo = subYears(parsedDateTo, 1);

  return {
    prevFrom,
    prevTo,
  };
};

export const getPreviousPeriodDates = (
  parsedDateFrom: Date,
  parsedDateTo: Date,
  selectedDateIndex: DateOptions,
) => {
  let prevFrom = new Date();
  let prevTo = new Date();

  switch (selectedDateIndex) {
    case DateOptions.Today:
      prevFrom = startOfDay(subDays(new Date(), 1));
      prevTo = startOfDay(subDays(new Date(), 1));

      break;
    case DateOptions.Yesterday:
      prevFrom = startOfDay(subDays(new Date(), 2));
      prevTo = startOfDay(subDays(new Date(), 2));

      break;
    case DateOptions.LastSevenDays:
      prevFrom = sub(new Date(), { days: 14 });
      prevTo = sub(new Date(), { days: 8 });
      break;
    case DateOptions.LastWeek:
      prevFrom = startOfWeek(subWeeks(new Date(), 2));
      prevTo = endOfWeek(subWeeks(new Date(), 2));

      break;
    case DateOptions.LastMonth:
      prevFrom = startOfMonth(subMonths(new Date(), 2));
      prevTo = endOfMonth(subMonths(new Date(), 2));
      break;
    case DateOptions.LastThirtyDays:
      prevFrom = sub(new Date(), { days: 60 });
      prevTo = sub(new Date(), { days: 31 });
      break;
    case DateOptions.LastSixtyDays:
      prevFrom = sub(new Date(), { days: 120 });
      prevTo = sub(new Date(), { days: 61 });
      break;
    case DateOptions.LastNinetyDays:
      prevFrom = sub(new Date(), { days: 180 });
      prevTo = sub(new Date(), { days: 91 });
      break;
    case DateOptions.LastOneTwentyDays:
      prevFrom = sub(new Date(), { days: 240 });
      prevTo = sub(new Date(), { days: 121 });
      break;
    case DateOptions.LastThreeSixtyFiveDays:
      prevFrom = sub(new Date(), { days: 730 });
      prevTo = sub(new Date(), { days: 366 });
      break;
    case DateOptions.LastThreeMonths:
      prevFrom = sub(new Date(), { days: 6 });
      prevTo = sub(new Date(), { days: 4 });
      break;
    case DateOptions.LastSixMonths:
      prevFrom = sub(new Date(), { days: 12 });
      prevTo = sub(new Date(), { days: 7 });
      break;
    case DateOptions.LastNineMonths:
      prevFrom = sub(new Date(), { days: 18 });
      prevTo = sub(new Date(), { days: 10 });
      break;
    case DateOptions.LastTwelveMonths:
      prevFrom = sub(new Date(), { days: 24 });
      prevTo = sub(new Date(), { days: 13 });
      break;
    case DateOptions.LastTwentyFourMonths:
      prevFrom = sub(new Date(), { days: 48 });
      prevTo = sub(new Date(), { days: 25 });
      break;
    case DateOptions.LastThirtySixMonths:
      prevFrom = sub(new Date(), { days: 72 });
      prevTo = sub(new Date(), { days: 37 });
      break;
    case DateOptions.MTD:
      prevFrom = startOfMonth(subMonths(new Date(), 1));
      prevTo = subMonths(new Date(), 1);

      break;
    case DateOptions.LastYear:
      prevFrom = startOfYear(subYears(new Date(), 2));
      prevTo = endOfYear(subYears(new Date(), 2));

      break;
    case DateOptions.YTD:
      const from = startOfYear(new Date());
      const to = new Date();
      prevFrom = startOfYear(subYears(from, 1));
      prevTo = subYears(to, 1);

      break;
    case DateOptions.Custom:
      const isFullMonthSelected = isFullMonth(parsedDateFrom, parsedDateTo);
      const isFullYearSelected = isFullYear(parsedDateFrom, parsedDateTo);
      const isMTDSelected = isMonthToDate(parsedDateFrom, parsedDateTo);
      const isYTDSelected = isYearToDate(parsedDateFrom, parsedDateTo);

      if (isFullMonthSelected) {
        prevFrom = startOfMonth(subMonths(parsedDateFrom, 1));
        prevTo = endOfMonth(subMonths(parsedDateTo, 1));
      } else if (isFullYearSelected) {
        prevFrom = startOfYear(subYears(parsedDateFrom, 1));
        prevTo = endOfYear(subYears(parsedDateTo, 1));
      } else if (isMTDSelected) {
        prevFrom = startOfMonth(subMonths(parsedDateFrom, 1));
        prevTo = subMonths(parsedDateTo, 1);
      } else if (isYTDSelected) {
        prevFrom = startOfYear(subYears(parsedDateFrom, 1));
        prevTo = subYears(parsedDateTo, 1);
      } else {
        const daysDifference = differenceInDays(parsedDateFrom, parsedDateTo);
        // @ts-ignore
        prevFrom = parsedDateFrom.setDate(parsedDateFrom.getDate() + daysDifference - 1);
        // @ts-ignore
        prevTo = parsedDateTo.setDate(parsedDateTo.getDate() + daysDifference - 1);
      }

      break;
    default:
      break;
  }
  return {
    prevFrom,
    prevTo,
  };
};

function DateDropdownProvider(props: any) {
  const router = useRouter();
  const [dateRange, setDateRange] = React.useState<RangeType>({
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  });
  const [compareRange, setCompareRange] = React.useState<CompareRangeType>({
    prevStartDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    prevEndDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
  });

  const [selectedDateIndex, setSelectedDateIndex] = React.useState(DateOptions.MTD);
  const [selectedDate, setSelectedDate] = React.useState<(typeof DATE_OPTIONS)[number]>(
    DATE_OPTIONS[DateOptions.MTD],
  );
  const [beforeNow, setBeforeNow] = React.useState(
    differenceInDays(new Date(), parseISO(dateRange.from)),
  );
  const { groupByValue, onGroupByChange, setGroupByValue } = useGroupBy();
  const { setShowGroupByDropdown, showGroupByDropdown } = useShowGroupByDropdown();
  const [compareSelect, changeCompareSelect] =
    React.useState<CompareDropdownType>("previousPeriod");

  React.useEffect(
    () => setBeforeNow(differenceInDays(new Date(), parseISO(dateRange.from))),
    [dateRange.from],
  );
  React.useEffect(() => {
    if (router.pathname === "/cohorts") {
      setSelectedDateIndex(DateOptions.LastYear);
      setSelectedDate(DATE_OPTIONS[DateOptions.LastYear]);
    }
  }, []);

  React.useEffect(() => {
    let tempDate = new Date();
    let from = new Date();
    let to = new Date();

    switch (selectedDateIndex) {
      case DateOptions.Today:
        break;
      case DateOptions.Yesterday:
        from = subDays(new Date(), 1);
        to = subDays(new Date(), 1);
        break;
      case DateOptions.LastSevenDays:
        from = sub(new Date(), { days: 7 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastWeek:
        tempDate = subWeeks(new Date(), 1);
        from = startOfWeek(tempDate);
        to = endOfWeek(tempDate);
        break;
      case DateOptions.LastMonth:
        tempDate = subMonths(new Date(), 1);
        from = startOfMonth(tempDate);
        to = endOfMonth(tempDate);
        break;
      case DateOptions.LastThirtyDays:
        from = sub(new Date(), { days: 31 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastSixtyDays:
        from = sub(new Date(), { days: 61 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastNinetyDays:
        from = sub(new Date(), { days: 91 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastOneTwentyDays:
        from = sub(new Date(), { days: 121 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastThreeSixtyFiveDays:
        from = sub(new Date(), { days: 366 });
        to = sub(new Date(), { days: 1 });
        break;
      case DateOptions.LastThreeMonths:
        from = startOfMonth(subMonths(new Date(), 3));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.LastSixMonths:
        from = startOfMonth(subMonths(new Date(), 6));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.LastNineMonths:
        from = startOfMonth(subMonths(new Date(), 9));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.LastTwelveMonths:
        from = startOfMonth(subMonths(new Date(), 12));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.LastTwentyFourMonths:
        from = startOfMonth(subMonths(new Date(), 24));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.LastThirtySixMonths:
        from = startOfMonth(subMonths(new Date(), 36));
        to = endOfMonth(subMonths(new Date(), 1));
        break;
      case DateOptions.MTD:
        from = startOfMonth(new Date());
        to = new Date();
        break;
      case DateOptions.LastYear:
        tempDate = subYears(new Date(), 1);
        from = startOfYear(tempDate);
        to = endOfYear(tempDate);
        break;
      case DateOptions.YTD:
        from = startOfYear(new Date());
        to = new Date();
        break;
      case DateOptions.Custom:
        setSelectedDate(DATE_OPTIONS[selectedDateIndex]);
        return;
      default:
        break;
    }

    setDateRange({
      from: format(from, "yyyy-MM-dd"),
      to: format(to, "yyyy-MM-dd"),
    });

    setSelectedDate(DATE_OPTIONS[selectedDateIndex]);
  }, [selectedDateIndex]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeCompareRange = React.useCallback(
    (newSelectValue: CompareDropdownType) => {
      const parsedDateFrom = parse(dateRange.from, "yyyy-MM-dd", new Date());
      const parsedDateTo = parse(dateRange.to, "yyyy-MM-dd", new Date());
      switch (newSelectValue) {
        case "previousYear": {
          const { prevFrom, prevTo } = getPreviousYearDates(parsedDateFrom, parsedDateTo);
          setCompareRange({
            prevStartDate: format(prevFrom, "yyyy-MM-dd"),
            prevEndDate: format(prevTo, "yyyy-MM-dd"),
          });
          break;
        }
        case "previousPeriod": {
          const { prevFrom, prevTo } = getPreviousPeriodDates(
            parsedDateFrom,
            parsedDateTo,
            selectedDateIndex,
          );

          setCompareRange({
            prevStartDate: format(prevFrom, "yyyy-MM-dd"),
            prevEndDate: format(prevTo, "yyyy-MM-dd"),
          });
          break;
        }
        default:
          break;
      }
    },
    [dateRange.from, dateRange.to, selectedDateIndex],
  );

  React.useEffect(() => {
    changeCompareRange(compareSelect);
  }, [changeCompareRange, compareSelect, dateRange, selectedDateIndex]);

  const value = React.useMemo(
    () => ({
      dateRange,
      setSelectedDateIndex,
      setDateRange,
      selectedDateIndex,
      selectedDate,
      beforeNow,
      compareRange,
      compareSelect,
      changeCompareSelect,
      groupByValue,
      onGroupByChange,
      setGroupByValue,
      showGroupByDropdown,
      setShowGroupByDropdown,
    }),
    [
      dateRange,
      selectedDateIndex,
      selectedDate,
      beforeNow,
      compareRange,
      compareSelect,
      groupByValue,
      onGroupByChange,
      setGroupByValue,
      showGroupByDropdown,
      setShowGroupByDropdown,
    ],
  );

  return <DateDropdownContext.Provider value={value} {...props} />;
}

function useDateDropdown() {
  const context = React.useContext(DateDropdownContext);

  if (context === undefined) {
    throw new Error("useDateDropdown must be used within an DateDropdownProvider");
  }
  return context;
}

export { DateDropdownProvider, useDateDropdown, DateDropdownContext };
