import { InsightsDataProps } from "../../lib/dashboard/insights/types";
import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";

const insightsEndpoint = "/api/dashboard/insights";

const ENDPOINTS = {
  fetchInsights: (params: InsightsDataProps) =>
    `${insightsEndpoint}?${createFilteredUrlParams<InsightsDataProps>(params)}`,
};

export { ENDPOINTS };
