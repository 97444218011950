import {
  ConversionsCards,
  PinnedCards,
  PinnedService,
  StoreOverviewCards,
  LtvCards,
  TikTokCards,
} from "../../../lib/pinned/types";
import { GoogleAdsCards, GSCCardsKeys, ReChargeCards } from "../../../types";
import { FBAdsCards } from "../../../lib/facebook/types";
import { GA4CardsKeys } from "../../../lib/google/ga4/types";
import { KlaviyoCardsKeys } from "../../../lib/klaviyo/types";

export const getEnumValueByTitle = (key: string, source: PinnedService) => {
  const currentEnum = (() => {
    switch (source) {
      case "googleAds":
        return GoogleAdsCards;
      case "fbAds":
        return FBAdsCards;
      case "storeOverview":
        return StoreOverviewCards;
      case "conversions":
        return ConversionsCards;
      case "ga4":
        return GA4CardsKeys;
      case "googleSearchConsole":
        return GSCCardsKeys;
      case "klaviyo":
        return KlaviyoCardsKeys;
      case "ltv":
        return LtvCards;
      case "tiktok":
        return TikTokCards;
      case "recharge":
        return ReChargeCards;
      default:
        throw new Error("There is no such a key for enum");
    }
  })();

  return currentEnum[key as keyof typeof currentEnum];
};

export const getObjectKeyBySource = (source: PinnedService) => {
  switch (source) {
    case "fbAds":
      return "fbAdsPinnedCards";
    case "googleAds":
      return "googleAdsPinnedCards";
    case "conversions":
      return "conversionsPinnedCards";
    case "storeOverview":
      return "storeOverviewPinnedCards";
    case "ga4":
      return "ga4PinnedCards";
    case "googleSearchConsole":
      return "googleSearchConsolePinnedCards";
    case "klaviyo":
      return "klaviyoPinnedCards";
    case "ltv":
      return "LtvPinnedCards";
    case "tiktok":
      return "tikTokPinnedCards";
    case "recharge":
      return "reChargePinnedCards";
    default:
      throw new Error(`There is no such a key in type PinnedCards: ${source}`);
  }
};

export function transformCardKeyInverted(cardKey: any, source: PinnedService) {
  if (source !== "conversions") return cardKey;
  switch (cardKey) {
    case "totalSpend":
      return "spend";
    case "blendedROAS":
      return "ROAS";
    case "blendedCPA":
      return "cpa";
    case "totalConversionValue":
      return "conversionValue";
    case "adConversionRate":
      return "conversionRate";
    default:
      return cardKey;
  }
}

export function transformObjectKeys(obj: any) {
  const transformedObj = Object.keys(obj).reduce((acc, sourceKey) => {
    if (obj[sourceKey] === null) return { ...acc, [sourceKey]: {} };
    const metricObject = Object.keys(obj[sourceKey as keyof PinnedCards]).reduce(
      (acc2, metricKey) => {
        const transformedKey = transformCardKeyInverted(metricKey, sourceKey as PinnedService);
        return { ...acc2, [transformedKey]: obj[sourceKey][metricKey] };
      },
      {},
    );
    return { ...acc, [sourceKey]: metricObject };
  }, {});

  return transformedObj;
}

export function transformCardKey(cardKey: string, source: PinnedService) {
  if (source !== "conversions") return cardKey;
  switch (cardKey) {
    case "spend":
      return "totalSpend";
    case "ROAS":
      return "blendedROAS";
    case "cpa":
      return "blendedCPA";
    case "conversionValue":
      return "totalConversionValue";
    case "conversionRate":
      return "adConversionRate";
    default:
      return cardKey;
  }
}
