import { KPIGroupValuesParams } from "../../lib/kpi";
import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";
import { IKPIMetricsByGroupParams } from "../hooks/useOperateKPIMetrics";

const metricsEndpoint = "/api/kpi/kpi-metrics";
const groupValues = "/api/kpi/group-values";

const ENDPOINTS = {
  KPIMetricsByPeriod: (params: KPIGroupValuesParams) =>
    `${groupValues}?${createFilteredUrlParams<KPIGroupValuesParams>(params)}`,
  KPIMetrics: (storeId: string) => `${metricsEndpoint}?storeId=${storeId}`,
  KPIMetricsByGroup: (params: IKPIMetricsByGroupParams) =>
    `${metricsEndpoint}?${createFilteredUrlParams<IKPIMetricsByGroupParams>(params)}`,
  deleteKPIMetrics: () => metricsEndpoint,
  addKPIMetrics: () => metricsEndpoint,
};
export { ENDPOINTS };
