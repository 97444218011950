import type { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";
import { SelectItem } from "../../../src/components/Select/types";

export type GroupByOption = "day" | "week" | "month" | "quarter" | "year";

export const groupByOptions: SelectItem<GroupByOption>[] = [
  { id: "day", title: "Day" },
  { id: "week", title: "Week" },
  { id: "month", title: "Month" },
];

export const additionalOptions: SelectItem<GroupByOption>[] = [
  { id: "quarter", title: "Quarter" },
  { id: "year", title: "Year" },
];

export type UseGroupBy = {
  groupByValue: GroupByOption;
  onGroupByChange: (e: SelectChangeEvent<GroupByOption>) => void;
  setGroupByValue: React.Dispatch<React.SetStateAction<GroupByOption>>;
};

export function useGroupBy(): UseGroupBy {
  const [groupByValue, setGroupByValue] = useState<GroupByOption>(groupByOptions[0].id);
  const onGroupByChange = (e: SelectChangeEvent<GroupByOption>) => {
    e.stopPropagation();
    setGroupByValue(e.target.value as GroupByOption);
  };

  return {
    groupByValue,
    onGroupByChange,
    setGroupByValue,
  };
}
