import { TargetsLastYearReqBody } from "../../pages/api/targets/last-year";
import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";

const baseUrl = "api/targets";
const ENDPOINTS = {
  targetsByPeriod: (params: TargetsLastYearReqBody) =>
    `${baseUrl}/last-year?${createFilteredUrlParams<TargetsLastYearReqBody>(params)}`,
  updateTargetValue: () => baseUrl,
  addNewTargets: () => baseUrl,
};

export { ENDPOINTS };
