import { getErrorMessage } from "../utils/common";
import * as googleSearchConsole from "./googleSearchConsole";
import * as googleAds from "./googleAds";
import * as dashboard from "./dashboard";
import * as onboarding from "./onboarding";
import * as settings from "./settings";
import * as googleAnalytics from "./googleAnalytics";
import * as attribution from "./attribution";
import * as facebookAds from "./facebookAds";
import * as onlineStore from "./online-store";
import * as signup from "./signup";
import * as store from "./store";
import * as profile from "./profile";
import * as token from "./token";
import * as adsOverview from "./adsOverview";
import * as klaviyo from "./klaviyo";
import * as recharge from "./recharge";
import * as benchmarks from "./benchmarks";
import * as creativeOverview from "./creativeOverview";
import * as tiktok from "./tiktok";
import * as costSettings from "./costSettings";
import * as orders from "./orders";
import * as products from "./products";
import * as storeOverview from "./storeOverview";
import * as finance from "./finance";
import * as ga4 from "./gA4";
import * as ltv from "./ltv";
import * as cohorts from "./cohorts";
import * as additionalEmails from "./additionalEmails";
import * as whiteLabel from "./whiteLabel";
import * as teamSettings from "./teamSettings";
import * as updatePinnedCard from "./pinnedCards";
import * as shipping from "./shipping";
import * as paidPlans from "./paidPlans";
import * as onboardingСhecklist from "./onboardingСhecklist";
import * as kpiGroups from "./kpiGroups";
import * as kpiMetrics from "./kpiMetrics";
import * as targets from "./targets";
import * as marketsCost from "./marketsCost";
import * as slackIntegration from "./slack";
import * as featureFlags from "./featureFlags";
import * as insights from "./insights";
import * as repeatCustomerProfitability from "./repeatCustomerProfitability";
import { CustomServerError } from "../../lib/errorTypes";

export type ResolverTypes<DataType = any> = {
  data: DataType;
  error: any;
  aborted?: boolean;
};

type ResolverData = {
  customURL?: string;
  retries?: number;
};

function request(endpoint = "", options?: any, data?: ResolverData) {
  return fetch(data?.customURL || endpoint, options);
}

class QueryFormatException extends Error {
  constructor(message: string) {
    super(message);
    this.name = "QueryFormatException";
  }
}

function queryFetcher<T = any>(endpoint = "", options?: any) {
  return fetch(endpoint, options)
    .then(async (result) => {
      const data = await result.json();

      if (result.status >= 400) {
        if ("customError" in data) {
          throw new CustomServerError(data.customError?.errorType, data.customError?.message);
        }

        throw new Error(getErrorMessage(data));
      }

      return data as T;
    })
    .catch((e) => {
      throw e;
    });
}

async function resolver(
  endpoint: string,
  options?: any,
  data?: ResolverData,
): Promise<ResolverTypes> {
  const result: ResolverTypes = {
    data: null,
    error: null,
    aborted: false,
  };
  try {
    const response = await request(endpoint, options, data);
    const body = await response.json();

    if (options?.signal?.aborted) {
      return result;
    }

    if (response.status >= 400) {
      result.error = body.errorMessage;
      return result;
    }

    result.data = body;
  } catch (e: any) {
    result.error = e?.message;
  }

  return result;
}

const api = {
  googleSearchConsole,
  attribution,
  googleAds,
  dashboard,
  onboarding,
  onlineStore,
  settings,
  googleAnalytics,
  facebookAds,
  signup,
  store,
  profile,
  token,
  adsOverview,
  klaviyo,
  recharge,
  benchmarks,
  creativeOverview,
  tiktok,
  costSettings,
  orders,
  products,
  storeOverview,
  finance,
  ga4,
  ltv,
  cohorts,
  additionalEmails,
  whiteLabel,
  teamSettings,
  updatePinnedCard,
  shipping,
  paidPlans,
  onboardingСhecklist,
  kpiGroups,
  kpiMetrics,
  marketsCost,
  slackIntegration,
  featureFlags,
  targets,
  insights,
  repeatCustomerProfitability,
};

export default api;

export { resolver, request, queryFetcher };
