import { useMutation, useQueryClient } from "react-query";
import { PinnedCardOrderItem, PinnedService } from "../../lib/pinned/types";
import api, { queryFetcher } from "../../src/APIResolvers";
import { useStoresContext } from "../StoresContext";

type UpdatePinnedCardProps = {
  storeId: string;
  service: PinnedService;
  metricId: number;
  isNewDashboard: boolean;
};

type UpdatePinnedCardDisplayOrderProps = {
  storeId: string;
  pinnedCardsDisplayOrder: PinnedCardOrderItem[];
};

export function useUpdatePinnedCard() {
  const client = useQueryClient();
  const { selectedStore } = useStoresContext();
  const storeId = selectedStore?.id || "";

  const { mutateAsync: onAddPinnedCard } = useMutation((values: UpdatePinnedCardProps) =>
    queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
      method: "POST",
      body: JSON.stringify(values),
    }),
  );

  const { mutateAsync: onDeletePinnedCard } = useMutation((values: UpdatePinnedCardProps) =>
    queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
      method: "DELETE",
      body: JSON.stringify(values),
    }),
  );

  const { mutateAsync: onUpdatePinnedCardsDisplayOrder } = useMutation(
    (values: UpdatePinnedCardDisplayOrderProps) =>
      queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCardsDisplayOrder(), {
        method: "POST",
        body: JSON.stringify(values),
      }),
    {
      onSuccess: () => {
        // update allMetrics
        client.invalidateQueries(["KPIMetrics", storeId]);
      },
    },
  );

  return { onAddPinnedCard, onDeletePinnedCard, onUpdatePinnedCardsDisplayOrder };
}
